<template>
  <el-form-item :prop="question.questionId" :rules="rules">
    <template #label>
      <span class="ques-title">
        {{ index + 1 }}. {{ question.questionText }}
      </span>
    </template>
    <el-checkbox-group v-model="answer">
      <el-checkbox
        v-for="item in choices"
        :key="item.choiceId"
        :label="item.choiceId"
        >{{ item.choiceText }}</el-checkbox
      >
    </el-checkbox-group>
  </el-form-item>
</template>

<script>
import survey from "../mixins/survey";
export default {
  mixins: [survey],
  data() {
    return { answerArr: [] };
  },
  computed: {
    answer: {
      get() {
        return this.value || [];
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .el-checkbox__label {
  font-size: 0.7292vw;
}
::v-deep(.el-checkbox-group) {
  .el-checkbox {
    display: flex;
    margin: 0;
    & + .el-checkbox {
      margin-top: 0.7813vw;
    }
    .el-checkbox__label {
      color: #606266;
      white-space: normal;
      line-height: 0.9375vw;
    }
    .el-checkbox__input {
      margin-top: 0.0521vw;
    }
  }
  .el-checkbox__inner {
    border: 0.0521vw solid #dcdfe6;
    border-radius: 100%;
    width: 0.7292vw;
    height: 0.7292vw;
    background-color: #fff;
    cursor: pointer;
    box-sizing: border-box;
    &::after {
      background: #f9680d;
      width: 0.4688vw;
      height: 0.4688vw;
      border-radius: 100%;
      background-color: #fff;
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.15s ease-in;
    }
  }
  .el-checkbox__input.is-checked {
    .el-checkbox__inner {
      background: #ffffff;
      border-color: #707070;
      &::after {
        background: #f9680d;
        transform: translate(-50%, -50%) scale(1);
      }
    }
    & + .el-checkbox__label {
      color: #606266;
    }
  }
}
</style>
