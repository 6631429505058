export default {
  props: {
    value: [String, Number, Array],
    index: Number,
    question: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    answer: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    choices() {
      return this.question?.choices || [];
    },
  },
  data() {
    return {
      rules: [{ required: true, message: "必须填写", trigger: "change" }],
    };
  },
  methods: {
    getLabel() {
      let label = this.answer;
      if (this.choices.length) {
        if (Array.isArray(this.answer)) {
          const arr =
            this.choices.filter((item) =>
              this.answer.includes(item.choiceId)
            ) || [];
          label = arr.map((item) => item.choiceText).join(",");
        } else {
          const obj =
            this.choices.find((item) => item.choiceId === this.answer) || {};
          label = obj.choiceText || this.answer;
        }
      }
      return label;
    },
  },
};
